import React, { useState, useRef, useEffect } from "react"
import { graphql, navigate } from "gatsby"
import get from "lodash/get"
import { Helmet } from "react-helmet"

import Seo from "../components/Seo"
import Breadcrumbs from "../components/Breadcrumbs"
import RecipeHero from "../components/Hero/RecipeHero"
import TagList from "../components/TagList/TagList"
import LocaleLink from "../components/LocaleLink"
import RichText from "../components/RichText"
import BlockList from "../components/PageLayout/BlockList"
import Cards from "../components/Cards/Cards"
import EmailIngredients from "../components/RecipePage/EmailIngredients"
import RecipeNutritionModal from "../components/Modals/RecipeNutritionModal"

import ServesIcon from "../images/svg/icon_serves.svg"
import PrepIcon from "../images/svg/icon_prep.svg"
import CookIcon from "../images/svg/icon_cook.svg"
import SearchIcon from "../images/svg/icon_search.svg"

import { useLocale } from "../context/LocaleContext"
import { getPlainTextList } from "../utils/utils_text"
import { getYtVideoId } from "../utils/utils_links"
import { filterBoolean, shuffleArray } from "../utils/utils_arrays"
import {
  getPlainText,
  getPrettyURL,
  getFirstArrayItem,
  isEmptyRichText,
} from "../utils"
import { PageModels, RecipeModel } from "../models/pages"
import { HeroSlide, SeoDataModel } from "../models/common"
import { Config } from "../models/config"
import { deBranchImage } from "../utils/utils_images"
import useDictionary from "../hooks/useDictionary"

import { fetchLivePreviewData, contentstackLivePreviewWrapper } from "../live-preview"

const defaultProps = {
  additional_images: [],
}

const getListingPage = (listingPages: any) => {
  return getFirstArrayItem(listingPages)?.pages
}

export const RecipePage: React.FC<RecipeModel> = props => {

  // page data  
  const _page =  deBranchImage(get(props, "data.page")) as any as RecipeModel;
  _page.pageContext = props.pageContext
  const [page, setPage] = useState(_page);

  //console.log(page.pageContext)
  // context
  const { localeDir } = useLocale().localeData

  // CMS data
  const _ingredientContent = get(
    props,
    "data.ingredientContent"
  ) as any as Config
  const [ingredientContent, setIngredientContent] = useState(_ingredientContent);

  const _popularSearch = get(props, "data.popularSearch") as any as Config
  const [popularSearch, setPopularSearch] = useState(_popularSearch);

  const _subscribe = get(props, "data.subscribe") as any as Config
  const [subscribe, setSubscribe] = useState(_subscribe);

  const _listingPage = getListingPage(
    get(props, "data.recipesPage") as any as PageModels
  );
  const [listingPage, setListingPage] = useState(_listingPage);

  const _recipes = get(props, "data.recipes.nodes") as any as RecipeModel[]
  const [recipes, setRecipes] = useState(_recipes);

  useEffect(() => {
      contentstackLivePreviewWrapper(fetchLivePreviewData(_page, setPage, props.pageContext, deBranchImage))
      contentstackLivePreviewWrapper(fetchLivePreviewData(_ingredientContent, setIngredientContent))
      contentstackLivePreviewWrapper(fetchLivePreviewData(_popularSearch, setPopularSearch))
      contentstackLivePreviewWrapper(fetchLivePreviewData(_subscribe, setSubscribe))
      contentstackLivePreviewWrapper(fetchLivePreviewData(_listingPage, setListingPage))
      contentstackLivePreviewWrapper(fetchLivePreviewData(_recipes, setRecipes))
  }, [])


  // state
  const [pageUrl, setPageUrl] = useState("")
  const [modalOpen, setModalOpen] = useState(false)
  const [featureRecipes] = useState<RecipeModel[]>(
    deBranchImage(
      shuffleArray(recipes.filter(x => x.id !== page.id)).slice(0, 3)
    )
  )

  // refs
  const searchref = useRef<HTMLInputElement>(null)
  const featuredHeading = useDictionary("Featured recipes", props.pageContext.locale)
  // page heading
  const heroHeading = getPlainText(page.hero.heading)
  // const navLabel = getPlainText(page.nav_label)
  const shareImage = page.seo?.share_image || page.hero.hero_image
  const shareDescription =
    page.seo?.description || getPlainText(page.introduction)

  // SEO Fields
  const seoData: SeoDataModel = {
    heading: page.hero.heading,
    metaTitle: page.seo?.title || heroHeading,
    metaDescription: shareDescription,
    metaLink: page.seo?.canonical_link,
    metaKeywords: page.seo?.keywords,
    metaImage: shareImage,
    slug: `/${props.pageContext.language}/${getPrettyURL(page)}`,
    locale: props.pageContext.locale,
    amp: true,
    pageContext: props.pageContext,
  }

  useEffect(() => {
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      const li: any = event.target
      li.classList.toggle("selected")
    }

    const handleKeyUp = (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === "Enter") {
        const li: any = event.target
        li.classList.toggle("selected")
      }
    }

    const methodItems = document.querySelectorAll(".method li")
    methodItems.forEach((item: any) => {
      item.tabIndex = 0
      item.addEventListener("click", handleClick)
      item.addEventListener("keyup", handleKeyUp)
    })

    return () => {
      methodItems.forEach((item: any) => {
        item.removeEventListener("click", handleClick)
        item.removeEventListener("click", handleKeyUp)
      })
    }
  }, [])

  // events
  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const handleSearch = () => {
    if (searchref.current && searchref.current.value) {
      navigate(`/${localeDir}/recipes/?s=${searchref.current.value}`)
    }
  }

  const heroSlide: HeroSlide[] = page.hero.hero_image
    ? [{ image: page.hero.hero_image }]
    : []

  // remove duplicate tags
  const categories = [...new Set(page.categories.map(item => item.id))]
    .map(id => page.categories.find(x => x.id === id))
    .filter(filterBoolean)

  /****
    Schema
  ****/
  const videos = page.additional_images?.filter(image => image.video_url)
  const video = videos?.length ? videos[0] : ""
  const videoThumb = video
    ? `http://img.youtube.com/vi/${getYtVideoId(video?.video_url)}/0.jpg`
    : ""
 const stepsText = useDictionary("Step", props.pageContext.locale)
  const recipeSchema = ` 
    {
        "@context": "http://schema.org/",
        "@type": "Recipe",
        "name": "${heroHeading}",
        "author": {
          "@type": "Organization",
          "name": "柠檬导航"
        },
        ${shareImage && `"image": "${shareImage.url}",`}
        "description": "${shareDescription}",
        "prepTime": "${("PT" + page.prep_time + "M").replace("mins", "")}",
        "cookTime": "${("PT" + page.cook_time + "M").replace("mins", "")}",
        ${
          page.nutrition?.energy_cal_per_serve
            ? `"nutrition": {
            "@type": "NutritionInformation",
            "calories": "${page.nutrition?.energy_cal_per_serve} calories"
          },`
            : ""
        }
        ${
          video
            ? `"video": {
            "@type": "VideoObject", 
            "name": "${heroHeading}", 
            "description": "How to make ${heroHeading}",  
            "thumbnailUrl":  "${videoThumb}",  
            "contentUrl": "${video.video_url}",  
            "embedUrl": "${video.video_url}",  
            "uploadDate": "${video.video_date}"  
          },`
            : ""
        }
        "recipeIngredient": [${getPlainTextList(page.ingredients)?.map(
          ingredient => `"${ingredient}"`
        )}],
        "recipeInstructions": [${getPlainTextList(page.method)?.map(
          method => `{"@type": "HowToStep", "text": "${method}"}`
        )}],
        "recipeCategory": "Vegetarian",
        "keywords": "${page.seo?.keywords || heroHeading}"
    }
  `
  const hasNutrition = !!Object.values(page.nutrition || {}).filter(Boolean)
    .length

  return (
    <div className="recipePage">
      <Helmet>
        <script type="application/ld+json">{recipeSchema}</script>
      </Helmet>

      <Seo {...seoData} />

      {/* Breadcrumbs */}
      <Breadcrumbs
        breadcrumbs={[...(listingPage.url_structure || []), listingPage]}
        pageContext={props.pageContext}
      />

      {/* Hero Images */}
      <RecipeHero slides={[...heroSlide, ...(page.additional_images || [])]} />

      {/* Hero */}
      <section className="hero">
        <div className="grid-container">
          <div className="grid-x grid-margin-x align-top">
            <div className="cell auto">
              <h2>{heroHeading}</h2>
            </div>
            <div
              className="cell large-shrink recipe__details"
              style={{ marginTop: 15 }}
            >
              <div className="grid-x">
                {page.serves && (
                  <div className="cell shrink recipe__timing">
                    <ServesIcon fill="#b6cf54" />
                    <span>
                      {useDictionary("Serves", props.pageContext.locale)}:{" "}
                      {page.serves}
                    </span>
                  </div>
                )}
                {page.prep_time && (
                  <div className="cell shrink recipe__timing">
                    <PrepIcon fill="#b6cf54" />
                    <span>
                      {useDictionary("Prep", props.pageContext.locale)}:{" "}
                      {page.prep_time}
                    </span>
                  </div>
                )}
                {page.cook_time && (
                  <div className="cell shrink recipe__timing">
                    <CookIcon fill="#b6cf54" />
                    <span>
                      {useDictionary("Cook", props.pageContext.locale)}:{" "}
                      {page.cook_time}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <hr className="show-for-print" />
        </div>
      </section>

      {/* Introduction / Ingredients / Method */}
      <section>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell">
              <div className="page__body">
                <TagList
                  tags={categories}
                  link={getPrettyURL(listingPage)}
                  pageContext={props.pageContext}
                />

                {!isEmptyRichText(page.introduction) && (
                  <>
                    <div className="hide-for-print" style={{ height: 20 }} />
                    <RichText
                      content={page.introduction}
                      cssClass="content hide-for-print"
                      pageContext={props.pageContext}
                    />
                    <div className="hide-for-print" style={{ height: 30 }} />
                  </>
                )}

                <div className="grid-x grid-margin-x">
                  <div className="cell medium-6 ingredients">
                    <h2>
                      {useDictionary("Ingredients", props.pageContext.locale)}
                    </h2>
                    <RichText
                      content={page.ingredients}
                      cssClass="content"
                      pageContext={props.pageContext}
                    />

                    <div className="ingredient__content content hide-for-print">
                      <EmailIngredients
                        heading={heroHeading || ""}
                        introduction={page.introduction}
                        ingredients={page.ingredients}
                        pageContext={props.pageContext}
                      />
                      {ingredientContent?.rich_text && (
                        <RichText
                          content={ingredientContent.rich_text}
                          pageContext={props.pageContext}
                        />
                      )}
                      {hasNutrition && (
                        <button
                          className="link"
                          style={{ marginTop: 30, display: "block" }}
                          onClick={openModal}
                        >
                          {useDictionary(
                            "View nutrition information per serve",
                            props.pageContext.locale
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="cell medium-6 method">
                    <h2>{useDictionary("Method", props.pageContext.locale)}</h2>
                    <RichText
                      content={page.method}
                      cssClass="content"
                      pageContext={props.pageContext}
                      listType={"ol"}
                      stepsText={stepsText}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Tips */}
      {!isEmptyRichText(page.tips) && (
        <section className="tips">
          <div className="grid-container">
            <div className="grid-x grid-margin-x">
              <div className="cell">
                <div className="page__body">
                  <div className="tips__box">
                    <h2>{useDictionary("Tips", props.pageContext.locale)}</h2>
                    <RichText
                      content={page.tips}
                      pageContext={props.pageContext}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* Search */}
      <section className="recipeSearch hide-for-print">
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell large-6">
              <h2 className="hide-for-medium">
                {useDictionary("Find a recipe", props.pageContext.locale)}
              </h2>
              <h2 className="show-for-medium">
                {useDictionary("Looking for more?", props.pageContext.locale)}
              </h2>
            </div>
            <div className="cell large-6">
              <div className="grid-x searchBox">
                <div className="cell auto">
                  <input
                    type="search"
                    className="search__input"
                    ref={searchref}
                  />
                </div>
                <div className="cell shrink">
                  <button className="button" onClick={handleSearch}>
                    <SearchIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="grid-x grid-margin-x search__links">
            <div className="cell shrink search__all show-for-medium">
              <LocaleLink
                to={getPrettyURL(listingPage)}
                pageContext={props.pageContext}
              >
                {useDictionary("All recipes A to Z", props.pageContext.locale)}
              </LocaleLink>
            </div>
            {popularSearch?.sani_tags && (
              <div className="cell auto search__popular">
                {useDictionary("Popular searches", props.pageContext.locale)}:{" "}
                <TagList
                  tags={popularSearch.sani_tags}
                  link={getPrettyURL(listingPage)}
                  pageContext={props.pageContext}
                />
              </div>
            )}
          </div>
        </div>
      </section>

      {!!featureRecipes?.length && (
        <>
          <br />
          <Cards
            heading={featuredHeading}
            cards={featureRecipes}
            cssClass=" hide-for-print"
            embed
            pageContext={props.pageContext}
          />
        </>
      )}

      {subscribe && (
        <BlockList
          items={subscribe?.blocks || []}
          pageContext={props.pageContext}
        />
      )}

      <RecipeNutritionModal
        modalOpen={modalOpen}
        closeModal={closeModal}
        nutrition={page.nutrition}
        pageContext={props.pageContext}
      />
    </div>
  )
}

RecipePage.defaultProps = defaultProps

export default RecipePage

/****
	Data
****/
export const query = graphql`
  query RecipePageByID($pageId: String!, $locale: String) {
    page: contentstackRecipe(id: { eq: $pageId }) {
      __typename
      uid
      ...RecipeFields
      hero {
        hero_image {
          ...RecipeHeroImageFields
        }
      }
      additional_images {
        image {
          ...CmsImageFields
          gatsbyImageData(width:910, height: 400, quality: 95, crop: "910:400")
        }
        image_focus
        video_url
        video_date
      }
      author {
        ...AuthorFields
      }
      introduction
      serves
      prep_time
      cook_time
      total_time
      difficulty
      importance
      ingredients
      method
      tips
      nutrition {
        per_quantity
        energy_kj_per_serve
        energy_kj_per_100g
        energy_cal_per_serve
        energy_cal_per_100g
        protein_per_serve
        protein_per_100g
        fat_per_serve
        fat_per_100g
        saturated_fat_per_serve
        saturated_fat_per_100g
        trans_fat_per_serve
        trans_fat_per_100g
        polyunsaturated_fat_per_serve
        polyunsaturated_fat_per_100g
        monounsaturated_fat_per_serve
        monounsaturated_fat_per_100g
        carbohydrate_per_serve
        carbohydrate_per_100g
        sugars_per_serve
        sugars_per_100g
        dietary_fibre_per_serve
        dietary_fibre_per_100g
        prebiotic_fibre_per_serve
        prebiotic_fibre_per_100g
        beta_glucan_per_serve
        beta_glucan_per_100g
        sodium_per_serve
        sodium_per_100g
        potassium_per_serve
        potassium_per_100g
        vitamin_a_per_serve
        vitamin_a_per_100g
        thiamin_per_serve
        thiamin_per_100g
        riboflavin_per_serve
        riboflavin_per_100g
        niacin_per_serve
        niacin_per_100g
        vitamin_b6_per_serve
        vitamin_b6_per_100g
        vitamin_b12_per_serve
        vitamin_b12_per_100g
        vitamin_c_per_serve
        vitamin_c_per_100g
        vitamin_d_per_serve
        vitamin_d_per_100g
        vitamin_e_per_serve
        vitamin_e_per_100g
        folate_per_serve
        folate_per_100g
        iron_per_serve
        iron_per_100g
        magnesium_per_serve
        magnesium_per_100g
        calcium_per_serve
        calcium_per_100g
        phosphorus_per_serve
        phosphorus_per_100g
        fibre_per_serve
        fibre_per_100g
        isoflavones_per_serve
        isoflavones_per_100g
        glycaemic_index_gi_per_serve
        glycaemic_index_gi_per_100g
        polyphenols_total_per_serve
        polyphenols_total_per_100g
        plant_sterols_per_serve
        plant_sterols_per_100g
        gluten_per_serve
        gluten_per_100g
        lactose_g_per_serve
        lactose_g_per_100g
        galactose_g_per_serve
        galactose_g_per_100g
      }
      products {
        ...ProductFlavourFields
      }
      seo {
        title
        description
        canonical_link
        keywords
        share_image {
          ...CmsImageFields
          gatsbyImageData(quality: 95)
        }
      }
    }

    recipesPage: contentstackConfig(
      code_id: { eq: "recipePage" }
      publish_details: { locale: { eq: $locale } }
    ) {
      pages {
        ... on Contentstack_recipe_listing {
          ...RecipeListingFields
        }
      }
    }

    ingredientContent: contentstackConfig(
      code_id: { eq: "ingredientContent" }
      publish_details: { locale: { eq: $locale } }
    ) {
      rich_text
    }

    popularSearch: contentstackConfig(
      code_id: { eq: "popularRecipeSearches" }
      publish_details: { locale: { eq: $locale } }
    ) {
      sani_tags {
        ...TagFields
      }
    }

    subscribe: contentstackConfig(
      code_id: { eq: "subscribeBanner" }
      publish_details: { locale: { eq: $locale }}
    ) {
      blocks {
        ... on Contentstack_banner {
          ...BannerFields
        }
      }
    }

    recipes: allContentstackRecipe(
      filter: { locale: { eq: $locale } }
      limit: 20
    ) {
      nodes {
        ...RecipeFields
      }
    }
  }

  fragment RecipeFields on Contentstack_recipe {
    id
    url
    hero {
      heading
      image_focus
      hero_image {
        ...RecipeHeroImageFields
      }
      listing_img: hero_image {
        ...CmsImageFields
        gatsbyImageData(height: 335, quality: 95)
      }
      mini_img: hero_image {
        ...CmsImageFields
        gatsbyImageData(height: 150, quality: 95)
      }
    }
    categories {
      ...TagFields
    }
    introduction
    seo {
      description
      share_image {
        ...CmsImageFields
        gatsbyImageData(quality: 95)
      }
    }
    enable_sites {
      sites
    }
    internal {
      type
    }
    publish_details {
      locale
    }
    updated_at
  }
`
